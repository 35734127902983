import assest from "@/json/assest";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import Link from "next/link";

interface StoreButtonComponentProps {
  className: string;
  sigin?: string;
}

const StoreWrapperr = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .font {
    @media (max-width: 380px) {
      font-size: 12px;
    }
  }
  .font-b {
    margin-bottom: 1rem;
  }
`;

export default function StoreButtonComponent({
  className,
  sigin,
}: StoreButtonComponentProps) {
  // google_playstore
  // apple_appstore
  return (
    <Box className={className}>
      {sigin === "true" ? (
        <StoreWrapperr>
          <Typography variant="body1" className="font">
            You must create an account through the app to sign in.
          </Typography>
          <Typography variant="body1" className="font font-b">
            Sign up for free in the app now!
          </Typography>
        </StoreWrapperr>
      ) : (
        <Typography variant="h4">Sign up for free in the app</Typography>
      )}

            <Box className="inside-box">
                <Link href='https://play.google.com/store/apps/details?id=com.paddletapp'>
                <Image src={assest.google_playstore} width={150} height={150} alt='' />
                </Link>
                <Link href='https://apps.apple.com/in/app/paddletapp/id6459411066' className="apple">
                    <Image src={assest.apple_appstore} width={150} height={150} alt='' />
                </Link>
            </Box>
        </Box>
    )
}
