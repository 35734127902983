// import styles from "@/styles/components/custombutton.module.scss";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { memo, useEffect, useState } from "react";
import { ButtonType } from "@/interface/common.interface";
import { styled } from "@mui/material";

const CustomBtn = styled(Button)`
&.button{
  padding: 14px 22px;
  border-radius: 50px;
  background-color: var(--color0BD3D3);
  border: 1px solid var(--color0BD3D3);
  color: var(--color100E48);
  min-width: 134px;
  .MuiTypography-caption {
    color: var(--color100E48);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2em;
    line-height: 1.1;
    text-transform: uppercase;
  }
  &:hover{
    background-color: var(--black);
    border: 1px solid var(--black);

    .MuiTypography-caption {
      color: var(--white);
    }
  }
}

&.buttonOutlined {
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid var(--primaryBlue);
  color: var(--primaryBlue);
}

&.buttonText {
  padding: 10px 20px;
  border-radius: 50px;
  color: var(--primaryBlue);
}
`
const CustomButtonMemo = ({
  children,
  variant = "contained",
  disabled = false,
  onClick,
  color = "inherit",
  size = "medium",
  fullWidth = false,
  endIcon,
  startIcon,
  type,
  loading = false,
  style
}: ButtonType) => {
  const [className, setClassName] = useState("button");

  useEffect(() => {
    if (variant === "text") {
      setClassName("buttonText");
    } else if (variant === "outlined") {
      setClassName("buttonOutlined");
    } else {
      setClassName("button");
    }
  }, [variant]);

  return (
    <CustomBtn
      className={className}
      variant={variant}
      disabled={disabled || loading}
      disableElevation
      onClick={onClick}
      color={color}
      size={size}
      fullWidth={fullWidth}
      endIcon={endIcon}
      startIcon={startIcon}
      type={type}
      style={style}
    >
      {loading && <CircularProgress size={15} color="inherit" />}
      {children}
    </CustomBtn>
  );
};

const CustomButton = memo(CustomButtonMemo);

export default CustomButton;
