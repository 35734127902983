import { mediaUrl } from "@/api/endpoints";
import CustomButton from "@/ui/Buttons/CustomButton";
import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

interface aboutProps {
  image: string;
  heading: string;
  subHeading: string;
  paragraph: string;
  addClass?: boolean;
  buttonText?: string;
  buttonLink?: string;
}

const AboutWrapper = styled(Box)`
  .abt_rgt {
    h2 {
      color: var(--white);
      text-transform: uppercase;
      max-width: 350px;
      @media(max-width: 767px){
        font-size: 35px;
      }
      @media(max-width: 479px){
        font-size: 30px;
      }
    }
    .sub_hd {
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: var(--white);
      font-weight: 500;
      font-size: 16px;
      margin: 25px 0 20px;
      @media(max-width: 767px){
        margin: 20px 0;
        font-size: 15px;
      }
    }
    .abt_btn {
      margin-top: 35px;
      button {
        min-width: 200px;
        padding: 17px 22px;
        @media(max-width: 767px) {
          min-width:170px;
          padding:13px 18px;
        }

      }
    }
  }
  .abt_lft {
    margin-right: -85px;
    @media(max-width: 1199px){
      display: table;
      margin: 0 auto; 
    }
  }
  .about_sec {
    /* margin-top: -160px; */
    padding: 50px 0;
    @media(max-width: 1199px){
      padding-bottom: 80px;
    }
    @media(max-width: 767px){
      padding-bottom: 50px;
      /* margin-top: -120px; */
    }
    @media(max-width: 479px){
      /* margin-top: -80px; */
    }
  }
  &.new_about {
    .about_sec {
      margin-top: 0px;
    }
  }

`;

export default function AboutComponent(props: aboutProps) {
  const { image, heading, subHeading, paragraph, addClass, buttonText, buttonLink } = props;
  const router = useRouter()
  return (
    <AboutWrapper
      className={!addClass ? "about_wrapper" : "about_wrapper new_about"}
    >
      <Box className="about_sec">
        <Container fixed>
          <Box className="about_outr">
            <Grid container spacing={2} alignItems="center">
              {
                image ? <Grid item lg={6} xs={12}>
                  <Box className="abt_lft">
                    <Image src={mediaUrl(image)} alt="" width={850} height={600} />
                  </Box>
                </Grid> : null
              }

              <Grid item lg={6} xs={12}>
                <Box className="abt_rgt">
                  {heading ? <Typography variant="h2">{heading}</Typography> : null}
                  {subHeading ? <Typography variant="body1" className="sub_hd">
                    {subHeading}
                  </Typography> : null}

                  {paragraph ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: paragraph }}></Typography> : null}

                  {buttonText ?
                    <Box className="abt_btn">
                      <CustomButton type="button" onClick={() => router.push(`https://${buttonLink}`)}>
                        <Typography variant="caption">{buttonText}</Typography>
                      </CustomButton>
                    </Box> : null
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </AboutWrapper>
  );
}
