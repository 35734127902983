import CustomButton from "@/ui/Buttons/CustomButton";
import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import Image from "next/image";
import React from "react";

import assest from "@/json/assest";
import { mediaUrl } from "@/api/endpoints";

interface bannerProps {
  image: string;
  width: number;
  height: number;
  imageP: string;
  width2: number;
  height2: number;
  addClass?: boolean;
  // heading: string;
  // subHeading: string;
  // paragraph: string;
  // isBtn:boolean;
  children: JSX.Element | JSX.Element[];
}

const BannerWrap = styled(Box)`
  .banner_outr {
    .ban_lft {
      margin-top: 100px;
      @media(max-width: 1199px){
        margin-top: 30px;
      }
      h1 {
        color: var(--white);
        text-transform: uppercase;
        @media(max-width: 767px){
          font-size: 40px;
        }
        @media(max-width: 479px){
          font-size: 35px;
        }
      }
      .sub_hd {
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: var(--white);
        font-weight: 500;
        font-size: 15px;
        margin: 25px 0 20px;
        @media(max-width: 767px){
          margin: 20px 0;
        }
        
      }
      .ban_btn {
        margin-top: 35px;
        @media(max-width: 767px){
          margin-top: 30px;
        }
        button {
          min-width: 200px;
          padding: 16px 22px;
          @media(max-width: 767px){
            min-width: 170px;
            padding: 13px 18px;
          }
        }
      }
    }
    .ban_rgt {
      position: relative;
      /* margin-top: -75px; */
      @media(max-width: 1199px){
        display: table;
        margin: 0 auto;
        margin-top: 5px;
      }
      .ban_desn {
        position: absolute;
        top: 119px;
        right: -120px;
        z-index: -1;
        max-width: 75%;
        @media (max-width: 1400px) {
          right: 0;

          max-width: 50%;
        }

        img {
          opacity: 0.1;
        }
      }
      .main_img {
        width: 475px;
      }
    }
  }
  .new_banner {
    padding-top: 40px;
    @media(max-width: 1199px){
      padding-top: 0;
    }
    .ban_rgt {
      margin-top: 0;
      text-align: center;
      .main_img {
        width: auto;
      }
      .ban_desn {
        position: absolute;
        top: 42px;
        right: -50px;
        z-index: -1;
        max-width: 75%;
        @media (max-width: 1400px) {
          right: 25px;
          max-width: 50%;
        }
      }
    }
  }
`;

export default function BannerComponent(props: bannerProps) {
  const { image, children, height, width, imageP, width2, height2, addClass } =
    props;

  return (
    <BannerWrap>
      <Box className="banner_sec">
        <Container fixed>
          <Box className={!addClass ? "banner_outr" : "banner_outr new_banner"}>
            <Grid container spacing={2}>
              <Grid item lg={5} xs={12}>
                {children}
              </Grid>
              <Grid item lg={7} xs={12}>
                <Box className="ban_rgt">
                  <Image
                    src={mediaUrl(image)}
                    alt=""
                    width={width}
                    height={height}
                    className="main_img"
                  />
                  <Box className="ban_desn">
                    <Image
                      src={imageP}
                      alt=""
                      width={width2}
                      height={height2}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </BannerWrap>
  );
}
