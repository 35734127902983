import AboutComponent from "@/components/AboutComponent/AboutComponent";
import BannerComponent from "@/components/BannerComponent/BannerComponent";
import assest from "@/json/assest";
import Wrapper from "@/layout/wrapper/Wrapper";
import { Box, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CustomButton from "@/ui/Buttons/CustomButton";
import { useQuery } from "react-query";
import { HomeMain, fetchHome } from "@/api/functions/cms.api";
import { useRouter } from "next/router";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import StoreButtonComponent from "@/components/StoreButtonComponent/StoreButtonComponent";
import styled from "@emotion/styled";
import { useAppSelector } from "@/hooks/useAppSelector";
import { NextSeo } from "next-seo";

export const getStaticProps: GetStaticProps = async () => {
  const res = await fetchHome();
  console.log(res);

  return {
    props: {
      res: res as HomeMain,
    },
    revalidate: 100,
  };
};

const StoreWrapper = styled(Box)`
  .center-store {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    h4 {
      margin-bottom: 1rem;
    }
    .inside-box {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 480px) {
        flex-direction: column;
        gap: 1rem;
      }
      a,
      img {
        width: 150px;
        height: 50px;
      }
      .apple {
        margin-left: 1rem;
        @media (max-width: 480px) {
          margin-left: -0.2rem;
        }
      }
    }
  }
`;

export type HomeSliderType = HomeSlider[];

export interface HomeSlider {
  title: string;
  smalltitle: string;
  content: string;
  button_text: string;
  button_link: string;
  _id: string;
}
export default function Home({
  res,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { userData, isLoggedIn } = useAppSelector((state) => state.userSlice);
  // const {data} = useQuery({queryKey:["fetchHome"], queryFn:fetchHome})
  console.log(res?.data, "res?.data");
  const router = useRouter();
  // const res = {...data?.data};
  const bannerData = [
    {
      heading: (
        <>
          Lorem <br /> Ipsum Dolor <br />
          Sit Amet
        </>
      ),
      subHeading: "Lorem ipsum dolor sit amet consectetur",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at dolor non orci molestie eleifend sed a lectus. Cras varius augue ac libero finibus, pulvinar venenatis ante blandit.",
    },
    {
      heading: (
        <>
          Lorem <br /> Ipsum Dolor <br />
          Sit Amet
        </>
      ),
      subHeading: "Lorem ipsum dolor sit amet consectetur",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at dolor non orci molestie eleifend sed a lectus. Cras varius augue ac libero finibus, pulvinar venenatis ante blandit.",
    },
    {
      heading: (
        <>
          Lorem <br /> Ipsum Dolor <br />
          Sit Amet
        </>
      ),
      subHeading: "Lorem ipsum dolor sit amet consectetur",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at dolor non orci molestie eleifend sed a lectus. Cras varius augue ac libero finibus, pulvinar venenatis ante blandit.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // lazyLoad: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <NextSeo noindex={false} nofollow={false} />
      <BannerComponent
        image={`home/${res?.data.section1_image}`}
        width={546}
        height={767}
        imageP={assest.bannerP}
        width2={505}
        height2={625}
      >
        <Box className="ban_lft">
          <Slider {...settings} className="global_slick">
            {res?.data?.section1?.map((data: HomeSlider) => {
              return (
                <Box className="banner-slide">
                  <Box className="banner-inr">
                    <Typography variant="h1">{data?.title}</Typography>
                    <Typography variant="body1" className="sub_hd">
                      {data.smalltitle}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: data.content }}
                    ></Typography>

                    {data?.button_text ? (
                      <Box className="ban_btn">
                        <CustomButton
                          type="button"
                          onClick={() => router.push(`${data?.button_link}`)}
                        >
                          <Typography variant="caption">
                            {data?.button_text}
                          </Typography>
                        </CustomButton>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </BannerComponent>

      <AboutComponent
        image={res?.data.section2_image}
        heading={res?.data.section2_title}
        subHeading={res?.data.section2_smalltitle}
        paragraph={res?.data.section2_content}
        buttonText={res?.data?.section2_button_text}
        buttonLink={res?.data?.section2_button_link}
      />

      <StoreWrapper>
        {!userData && <StoreButtonComponent className="center-store" />}
      </StoreWrapper>
    </Wrapper>
  );
}
