import dynamic from "next/dynamic";
import React, { Suspense, useEffect } from "react";
import NextProgress from "next-progress";
import HeaderSkeleton from "@/ui/Skeletons/HeaderSkeleton";
import useOnlineStatus from "@/hooks/useDetectOnline";
import { Box } from "@mui/material";
import assest from "@/json/assest";
import Image from "next/image";
import Seo from "@/components/Seo/Seo";
import { useRouter } from "next/router";
import AuthWrapper from "./AuthWrapper";

const Header = dynamic(() => import("../Header/Header"), { suspense: true });
const Footer = dynamic(() => import("../Footer/Footer"), { suspense: true });

interface wrapperProps {
  children: JSX.Element | JSX.Element[];
}

const Wrapper = (props: wrapperProps) => {
  const { children } = props;

  // useEffect(() => {
  //   if (document) {
  //     var setHeight = function() {
  //       const hdrElm = document.querySelector<HTMLElement>(".main_head");
  //       const hdrHeight = hdrElm?.clientHeight;
  //       const paaddngELM = document.querySelector<HTMLElement>(".body_content");
  //       if (!!paaddngELM) {
  //         paaddngELM.style.paddingTop = hdrHeight + "px";
  //       }
  //     }
  //     setHeight();
  //     window.addEventListener("load", (event) => {
  //       setHeight();
  //     });
  //     window.addEventListener("resize", (event) => {
  //       setHeight();
  //     });
  //   }
  // },[]);

  const router = useRouter();

const routerText = router.pathname.split("")

routerText.shift()
const favText  = routerText.join("").toString().toUpperCase()

// console.log(favText,"dsafsdf");

// console.log('=================Hello==========================')



  useOnlineStatus();
  return (
    <>
      {/* <NextProgress height={8} color="green" /> */}
      <Seo
        // title={
        //   router.pathname === "/"
        //     ? 
        //     : `Paddle Tap || ${favText}`
        // }
        title={
          `PaddleTapp`
        }
        canonical={""}
        description={""}
        url={""}
        image={""}
      />
      <Suspense fallback={<HeaderSkeleton />}>
        <Header />
      </Suspense>
      <Box className="body_content">
        <Box className="body_img1">
          <Image src={assest.graphic1} alt="" width={623} height={1100} />
        </Box>
        <Box className="body_img2">
          <Image src={assest.graphic4} alt="" width={1000} height={1100} />
        </Box>

      <AuthWrapper>
        {children}
      </AuthWrapper>
      </Box>

      <Suspense fallback={<HeaderSkeleton />}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Wrapper;
